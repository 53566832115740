export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'

export const GET_PRODUCT_SUGGESTIONS = 'GET_PRODUCT_SUGGESTIONS'
export const GET_PRODUCT_SUGGESTIONS_SUCCESS = 'GET_PRODUCT_SUGGESTIONS_SUCCESS'
export const GET_PRODUCT_SUGGESTIONS_ERROR = 'GET_PRODUCT_SUGGESTIONS_ERROR'
