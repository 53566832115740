export const SAFETY_STANDARDS_UPDATING = 'SAFETY_STANDARDS_UPDATING'
export const SAFETY_STANDARDS_UPDATE_SUCCESS = 'SAFETY_STANDARDS_UPDATE_SUCCESS'
export const SAFETY_STANDARDS_UPDATE_ERROR = 'SAFETY_STANDARDS_UPDATE_ERROR'

export const CLASSIFICATIONS_UPDATING = 'CLASSIFICATIONS_UPDATING'
export const CLASSIFICATIONS_UPDATE_SUCCESS = 'CLASSIFICATIONS_UPDATE_SUCCESS'
export const CLASSIFICATIONS_UPDATE_ERROR = 'CLASSIFICATIONS_UPDATE_ERROR'

export const PROCESSING_TYPES_UPDATING = 'PROCESSING_TYPES_UPDATING'
export const PROCESSING_TYPES_UPDATE_SUCCESS = 'PROCESSING_TYPES_UPDATE_SUCCESS'
export const PROCESSING_TYPES_UPDATE_ERROR = 'PROCESSING_TYPES_UPDATE_ERROR'

export const MARKET_TYPES_UPDATING = 'MARKET_TYPES_UPDATING'
export const MARKET_TYPES_UPDATE_SUCCESS = 'MARKET_TYPES_UPDATE_SUCCESS'
export const MARKET_TYPES_UPDATE_ERROR = 'MARKET_TYPES_UPDATE_ERROR'

export const SERVICES_UPDATING = 'SERVICES_UPDATING'
export const SERVICES_UPDATE_SUCCESS = 'SERVICES_UPDATE_SUCCESS'
export const SERVICES_UPDATE_ERROR = 'SERVICES_UPDATE_ERROR'
