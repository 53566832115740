export const MESSAGE_ALL = 'MESSAGE_ALL'
export const MESSAGE_ALL_SUCCESS = 'MESSAGE_ALL_SUCCESS'
export const MESSAGE_ALL_ERROR = 'MESSAGE_ALL_ERROR'

export const CLEAR_MESSAGE_ALL_NOTIFICATION = 'CLEAR_MESSAGE_ALL_NOTIFICATION'

export const MESSAGE_ALL_FILE_UPLOAD_REQUEST = 'MESSAGE_ALL_FILE_UPLOAD_REQUEST'
export const MESSAGE_ALL_FILE_UPLOAD_SUCCESS = 'MESSAGE_ALL_FILE_UPLOAD_SUCCESS'
export const MESSAGE_ALL_FILE_UPLOAD_ERROR = 'MESSAGE_ALL_FILE_UPLOAD_ERROR'
export const CLEAR_MESSAGE_ALL_FILE = 'CLEAR_MESSAGE_ALL_FILE'
