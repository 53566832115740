export const SIGNUP_REQUESTING = 'SIGNUP_REQUESTING'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'

export const CLEAR_SIGNUP_STATE = 'CLEAR_SIGNUP_STATE'

export const RESEND_CONFIRMATION_REQUESTING = 'RESEND_CONFIRMATION_REQUESTING'
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS'
export const RESEND_CONFIRMATION_ERROR = 'RESEND_CONFIRMATION_ERROR'

export const CLEAR_RESEND_NOTIFICATION = 'CLEAR_RESEND_NOTIFICATION'
