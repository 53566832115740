export const USER_MESSAGES_REQUESTING = 'USER_MESSAGES_REQUESTING'
export const USER_MESSAGES_SUCCESS = 'USER_MESSAGES_SUCCESS'
export const USER_MESSAGES_ERROR = 'USER_MESSAGES_ERROR'

export const CONVERSATION_REQUESTING = 'CONVERSATION_REQUESTING'
export const CONVERSATION_REQUEST_SUCCESS = 'CONVERSATION_REQUEST_SUCCESS'
export const CONVERSATION_REQUEST_ERROR = 'CONVERSATION_REQUEST_ERROR'

export const BLOCK_CONVERSATION_REQUESTING = 'BLOCK_CONVERSATION_REQUESTING'
export const BLOCK_CONVERSATION_REQUEST_SUCCESS = 'BLOCK_CONVERSATION_REQUEST_SUCCESS'
export const BLOCK_CONVERSATION_REQUEST_ERROR = 'BLOCK_CONVERSATION_REQUEST_ERROR'

export const MESSAGE_SENDING = 'MESSAGE_SENDING'
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS'
export const MESSAGE_SEND_ERROR = 'MESSAGE_SEND_ERROR'

export const MESSAGE_FILE_UPLOAD_REQUEST = 'MESSAGE_FILE_UPLOAD_REQUEST'
export const MESSAGE_FILE_UPLOAD_SUCCESS = 'MESSAGE_FILE_UPLOAD_SUCCESS'
export const MESSAGE_FILE_UPLOAD_ERROR = 'MESSAGE_FILE_UPLOAD_ERROR'
export const CLEAR_MESSAGE_FILE = 'CLEAR_MESSAGE_FILE'

export const UNREAD_MESSAGES_REQUESTING = 'UNREAD_MESSAGES_REQUESTING'
export const UNREAD_MESSAGES_REQUEST_SUCCESS = 'UNREAD_MESSAGES_REQUEST_SUCCESS'
export const UNREAD_MESSAGES_REQUEST_ERROR = 'UNREAD_MESSAGES_REQUEST_ERROR'

export const CLEAR_SEND_MESSAGE_NOTIFICATION = 'CLEAR_SEND_MESSAGE_NOTIFICATION'
