export const SEARCH_AUTOCOMPLETE_REQUEST = 'SEARCH_AUTOCOMPLETE_REQUEST'
export const SEARCH_AUTOCOMPLETE_SUCCESS = 'SEARCH_AUTOCOMPLETE_SUCCESS'
export const SEARCH_AUTOCOMPLETE_ERROR = 'SEARCH_AUTOCOMPLETE_ERROR'

export const SEARCH_QUERY_REQUESTING = 'SEARCH_QUERY_REQUESTING'
export const SEARCH_QUERY_SUCCESS = 'SEARCH_QUERY_SUCCESS'
export const SEARCH_QUERY_ERROR = 'SEARCH_QUERY_ERROR'

export const CLEAR_SEARCH_QUERY = 'CLEAR_SEARCH_QUERY'

export const CLASSIFIEDS_SEARCH_REQUESTING = 'CLASSIFIEDS_SEARCH_REQUESTING'
export const CLASSIFIEDS_SEARCH_SUCCESS = 'CLASSIFIEDS_SEARCH_SUCCESS'
export const CLASSIFIEDS_SEARCH_ERROR = 'CLASSIFIEDS_SEARCH_ERROR'

export const GET_SERVICES_LIST = 'GET_SERVICES_LIST'
export const GET_SERVICES_LIST_SUCCESS = 'GET_SERVICES_LIST_SUCCESS'
export const GET_SERVICES_LIST_ERROR = 'GET_SERVICES_LIST_ERROR'

export const GET_CLASSIFICATIONS_LIST = 'GET_CLASSIFICATIONS_LIST'
export const GET_CLASSIFICATIONS_LIST_SUCCESS = 'GET_CLASSIFICATIONS_LIST_SUCCESS'
export const GET_CLASSIFICATIONS_LIST_ERROR = 'GET_CLASSIFICATIONS_LIST_ERROR'

export const GET_PAYMENT_METHODS_LIST = 'GET_PAYMENT_METHODS_LIST'
export const GET_PAYMENT_METHODS_LIST_SUCCESS = 'GET_PAYMENT_METHODS_LIST_SUCCESS'
export const GET_PAYMENT_METHODS_LIST_ERROR = 'GET_PAYMENT_METHODS_LIST_ERROR'

export const GET_SAFETY_STANDARDS_LIST = 'GET_SAFETY_STANDARDS_LIST'
export const GET_SAFETY_STANDARDS_LIST_SUCCESS = 'GET_SAFETY_STANDARDS_LIST_SUCCESS'
export const GET_SAFETY_STANDARDS_LIST_ERROR = 'GET_SAFETY_STANDARDS_LIST_ERROR'

export const GET_PROCESSING_TYPES_LIST = 'GET_PROCESSING_TYPES_LIST'
export const GET_PROCESSING_TYPES_LIST_SUCCESS = 'GET_PROCESSING_TYPES_LIST_SUCCESS'
export const GET_PROCESSING_TYPES_LIST_ERROR = 'GET_PROCESSING_TYPES_LIST_ERROR'

export const GET_MARKET_TYPES_LIST = 'GET_MARKET_TYPES_LIST'
export const GET_MARKET_TYPES_LIST_SUCCESS = 'GET_MARKET_TYPES_LIST_SUCCESS'
export const GET_MARKET_TYPES_LIST_ERROR = 'GET_MARKET_TYPES_LIST_ERROR'

export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST'
export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS'
export const GET_CATEGORIES_LIST_ERROR = 'GET_CATEGORIES_LIST_ERROR'
