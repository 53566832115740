export const PROFILE_REQUESTING = 'PROFILE_REQUESTING'
export const PROFILE_REQUEST_SUCCESS = 'PROFILE_REQUEST_SUCCESS'
export const PROFILE_REQUEST_ERROR = 'PROFILE_REQUEST_ERROR'

export const BASIC_PROFILE_REQUESTING = 'BASIC_PROFILE_REQUESTING'
export const BASIC_PROFILE_REQUEST_SUCCESS = 'BASIC_PROFILE_REQUEST_SUCCESS'
export const BASIC_PROFILE_REQUEST_ERROR = 'BASIC_PROFILE_REQUEST_ERROR'

export const PUBLIC_PROFILE_REQUESTING = 'PUBLIC_PROFILE_REQUESTING'
export const PUBLIC_PROFILE_REQUEST_SUCCESS = 'PUBLIC_PROFILE_REQUEST_SUCCESS'
export const PUBLIC_PROFILE_REQUEST_ERROR = 'PUBLIC_PROFILE_REQUEST_ERROR'

export const FEATURED_PROFILES_REQUESTING = 'FEATURED_PROFILES_REQUESTING'
export const FEATURED_PROFILES_REQUEST_SUCCESS = 'FEATURED_PROFILES_REQUEST_SUCCESS'
export const FEATURED_PROFILES_REQUEST_ERROR = 'FEATURED_PROFILES_REQUEST_ERROR'

export const NEW_PROFILES_REQUESTING = 'NEW_PROFILES_REQUESTING'
export const NEW_PROFILES_REQUEST_SUCCESS = 'NEW_PROFILES_REQUEST_SUCCESS'
export const NEW_PROFILES_REQUEST_ERROR = 'NEW_PROFILES_REQUEST_ERROR'

export const DELETE_CONNECTION_REQUESTING = 'DELETE_CONNECTION_REQUESTING'
export const DELETE_CONNECTION_REQUEST_SUCCESS = 'DELETE_CONNECTION_REQUEST_SUCCESS'
export const DELETE_CONNECTION_REQUEST_ERROR = 'DELETE_CONNECTION_REQUEST_ERROR'

export const PROFILE_FILE_UPLOAD_REQUEST = 'PROFILE_FILE_UPLOAD_REQUEST'
export const PROFILE_FILE_UPLOAD_REQUEST_SUCCESS = 'PROFILE_FILE_UPLOAD_REQUEST_SUCCESS'
export const PROFILE_FILE_UPLOAD_REQUEST_ERROR = 'PROFILE_FILE_UPLOAD_REQUEST_ERROR'

export const PROFILE_FILE_DELETE_REQUEST = 'PROFILE_FILE_DELETE_REQUEST'
export const PROFILE_FILE_DELETE_REQUEST_SUCCESS = 'PROFILE_FILE_DELETE_REQUEST_SUCCESS'
export const PROFILE_FILE_DELETE_REQUEST_ERROR = 'PROFILE_FILE_DELETE_REQUEST_ERROR'
