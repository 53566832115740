export const PROFILE_REQUESTING = 'PROFILE_REQUESTING'
export const PROFILE_REQUEST_SUCCESS = 'PROFILE_REQUEST_SUCCESS'
export const PROFILE_REQUEST_ERROR = 'PROFILE_REQUEST_ERROR'

export const MANAGED_PROFILES_REQUESTING = 'MANAGED_PROFILES_REQUESTING'
export const MANAGED_PROFILES_REQUEST_SUCCESS = 'MANAGED_PROFILES_REQUEST_SUCCESS'
export const MANAGED_PROFILES_REQUEST_ERROR = 'MANAGED_PROFILES_REQUEST_ERROR'

export const CONNECTION_REQUEST_SENDING = 'CONNECTION_REQUEST_SENDING'
export const CONNECTION_REQUEST_SEND_SUCCESS = 'CONNECTION_REQUEST_SEND_SUCCESS'
export const CONNECTION_REQUEST_SEND_ERROR = 'CONNECTION_REQUEST_SEND_ERROR'
