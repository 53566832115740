export const PASSWORD_RESET_REQUESTING = 'PASSWORD_RESET_REQUESTING'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS'
export const SET_NEW_PASSWORD_ERROR = 'SET_NEW_PASSWORD_ERROR'

export const CLEAR_PASSWORD_RESET_NOTIFICATIONS = 'CLEAR_PASSWORD_RESET_NOTIFICATIONS'
export const CLEAR_SUCCESS_STATE = 'CLEAR_SUCCESS_STATE'
